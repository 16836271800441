import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto"; // Import Chart.js
import "./Analytics.css";

function Analytics() {
  const [ratingData, setRatingData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [ratingChart, setRatingChart] = useState(null);
  const [eventChart, setEventChart] = useState(null);

  useEffect(() => {
    // Fetch analytics data based on logged-in service provider's email from local storage
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail) {
      fetchAnalyticsData(userEmail);
    }

    // Cleanup function
    return () => {
      if (ratingChart) ratingChart.destroy();
      if (eventChart) eventChart.destroy();
    };
  }, []);

  const fetchAnalyticsData = async (email) => {
    try {
      const response = await fetch(
        `https://eventplanner.azurewebsites.net/analytics?email=${email}`
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Analytics Data:", data);
        setRatingData(data.analytics_data.ratings);
        setEventData(data.analytics_data.events);
      } else {
        console.error("Failed to fetch analytics data");
      }
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };

  useEffect(() => {
    if (ratingData) {
      if (ratingChart) ratingChart.destroy(); // Destroy existing chart instance
      renderRatingChart();
    }
    if (eventData) {
      if (eventChart) eventChart.destroy(); // Destroy existing chart instance
      renderEventChart();
    }
  }, [ratingData, eventData]);

  const renderRatingChart = () => {
    const ctx = document.getElementById("ratingChart");
    if (!ctx) return;
    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: Object.keys(ratingData),
        datasets: [
          {
            label: "Average Rating",
            data: Object.values(ratingData),
            backgroundColor: "rgba(0, 206, 209, 0.6)",
            borderWidth: 1,
          },
        ],
      },
    });
    setRatingChart(chart);
  };

  const renderEventChart = () => {
    const ctx = document.getElementById("eventChart");
    if (!ctx) return;
    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: Object.keys(eventData),
        datasets: [
          {
            label: "Number of Events",
            data: Object.values(eventData),
            backgroundColor: "rgba(148, 0, 211, 0.6)",
            borderWidth: 1,
          },
        ],
      },
    });
    setEventChart(chart);
  };

  return (
    <div className="analytics-container">
      <div className="graph-box">
        <p>Rating Analytics</p>
        <canvas id="ratingChart"></canvas>
      </div>
      <div className="graph-box">
        <p>Number of Events Analytics</p>
        <canvas id="eventChart"></canvas>
      </div>
    </div>
  );
}

export default Analytics;
